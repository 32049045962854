<script>
import Splash from '@/components/Splash.vue'
import { Locale } from '@/lib/enums'

export default {
  name: 'App',

  components: {
    Splash
  },
  computed: {
    initialized() {
      return this.$store.state.init.done // && this.$store.state.user.init.done
    }
  },
  created() {
    // console.log('App created')
    this.$store.dispatch('init', { $store: this.$store })
  },
  watch: {
    '$store.state.user.data'(next, prev) {
      // do nothing for register page - it manages redirects itself
      if (this.$route.name === 'RegisterPage')
        return
      if (next) {
        if (!next.approved && !next.reviewDate)
          this.$router.push({ name: 'AccountUnderReviewPage' })
        if (!next.approved && !!next.reviewDate)
          this.$router.push({ name: 'AccountRejectedPage' })
        if (!prev && next.approved && !!next.reviewDate) {
          // restore logged user profile
          const lc = Locale[next.locale]
          this.$i18n.locale = lc
          this.$store.commit('ui/setLocale', lc)
        }
      } else {
        this.$router.push({ name: 'LoginPage' })
      }
    },
    '$store.state.ui.locale'(next) {
      if (!this.$store.state.user.data) return
      this.$store.dispatch('user/setLocale', next)
    }
  }
}
</script>

<template>
  <v-app>
    <v-main class="main-template">
      <Splash id="app-splash" :loader="false"/>
      <router-view/>
    </v-main>
    <div class="main-template-unavailable-content justify-content-center align-items-center pa-5 text-center">
      <div>
        <span class="narrow-browser-emoji">😱</span><br/><span v-html="$t('narrowBrowser')"></span>
      </div>
    </div>
  </v-app>
</template>

<style lang="scss" scoped>
/*
@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
#app-splash {
  z-index: 20;

  &.hidden {
    visibility: hidden ;
    animation: fadeOut 1s;
  }
}
<<<<<<< HEAD
*/
.main-template-unavailable-content{
  display: none;
}
@media(max-width:1024px){
  .main-template{
    display: none!important;
  }
  .narrow-browser-emoji{
    font-size: 100px;
  }
  .main-template-unavailable-content{
    display: flex!important;
    font-size: 30px;
    position: fixed;
    width:100%;
    height: 100%;
    top:0;
    left:0;
    height: 100vh;
  }
}


</style>

/**
  User store module
  Manages logged user profile and app activity
*/
import Jimp from 'jimp'
import file2buffer from '@/utils/file2buffer'
import genID from '@/utils/genID'
import can from '@/utils/can'
const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')
import firestoreCollection from '@/store/firestore'
import init from '@/store/init'
import dm from '@/store/dm'

import * as Sentry from "@sentry/vue";


import * as Enums from '@/lib/enums'
import { collection as Collections} from '@/lib/collections'

// console.log(Collections)
// const User = Collections.user()
const Document = Collections.user.media()
const UserEmail = {
  metadata: null,
  labels: [],
  data: null,
  timestamp: null,
  to: [],
  toUsers: [],
  userEmails: []
}

// temporary to Campus default environment for registered users
const DEFAULT_ENV = Enums.Scene.Campus

export default {
  namespaced: true,
  modules: { init, dm },
  state: {
    /** Models::User data of logged in user */
    data: null,
    /** Promise::resolve function for next action */
    nextHandler: null,
    /** online interval */
    onlineTimer: null,
    ///** briefcase media subscription */
    //briefcase: null
  },
  mutations: {
    setData: (state, data = null) => {
      state.data = data
      state.logged = !!data
      if (state.nextHandler) {
        state.nextHandler(data)
        state.nextHandler = null
      }
    },
    setNextHandler: (state, handler) => {
      state.nextHandler = handler
    },
    setOnlineTimer: (state, timer) => {
      if (state.onlineTimer)
        clearInterval(state.onlineTimer)
      state.onlineTimer = timer
    },
    //setBriefcase: (state, next) => {
      //if (state.briefcase)
        //state.briefcase()
      //state.briefcase = next
    //},
  },
  actions: {
    /** action to wait user login procedure */
    next: ctx => new Promise(accept => {
      if (ctx.state.data)
        accept(true)
      else
        ctx.commit('setNextHandler', accept)
    }),
    /** User store initialization */
    initialize: async (ctx, { $store }) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      firebase.auth().onAuthStateChanged(async user => {
        //console.log('auth', !!user)
        if (!user) {
          ctx.commit('setData', null)
          ctx.dispatch('admin/unsubscribe', null, { root: true })
          ctx.dispatch('dm/unsubscribe')
          if ($store.hasModule(['user', 'media']))
            $store.unregisterModule(['user', 'media'])
          if ($store.hasModule(['user', 'score']))
            $store.unregisterModule(['user', 'score'])
          if ($store.hasModule(['user', 'email']))
            $store.unregisterModule(['user', 'email'])
          if ($store.hasModule(['user', 'calendar']))
            $store.unregisterModule(['user', 'calendar'])
          if ($store.hasModule(['user', 'notification']))
            $store.unregisterModule(['user', 'notification'])
          ctx.commit('setOnlineTimer', null)
          //ctx.commit('setBriefcase', null)
          ctx.commit('init/set', false)
          return
        }
        const ref = firebase.firestore().collection('user').doc(user.uid)
        ref.onSnapshot(async snap => {
          /*
             it's possible, that for fresh registered accounts
             user document is stil not exists, because db record
             is created by firebase function

             because it's only one possibility for authorized user
             get undefined db record - just skip it without error thowing
           */
          if (!snap.data()) return
          ctx.commit('setData', Object.assign({},snap.data(), {uid: user.uid}))
          // initialize user's media collection if user is not guest
          if (can(ctx.rootState, 'authorized') && !$store.hasModule(['user', 'media'])) {
            $store.registerModule(['user','media'], firestoreCollection(ref.collection('media'), Document))
            $store.registerModule(['user','score'], firestoreCollection(ref.collection('score'), Collections.user.score()))
            $store.registerModule(['user','email'], firestoreCollection(ref.collection('email'), UserEmail))
            $store.registerModule(['user','calendar'], firestoreCollection(ref.collection('calendar'), Collections.user.calendar()))
            $store.registerModule(['user','notification'], firestoreCollection(ref.collection('notification'), Collections.user.notification()))
            //ctx.commit('setBriefcase', null)
            await ctx.dispatch('dm/subscribe')
            await ctx.dispatch('media/subscribe')
            await ctx.dispatch('score/subscribe')
            //await ctx.dispatch('email/subscribe')
            await ctx.dispatch('calendar/subscribe')
            await ctx.dispatch('notification/subscribe')
            ctx.dispatch('updateProfile', { profile: {
              lastActivity: new Date()
            }})
            const timer = setInterval(async () => {
              ctx.dispatch('updateProfile', { profile: {
                lastActivity: new Date()
              }})
            }, 1000 * 60)
            ctx.commit('setOnlineTimer', timer)
          }
          // iniitialize admin store module if user can admin right
          if (can(ctx.rootState, 'admin')) {
            await ctx.dispatch('admin/subscribe', null, { root: true })
          }

          // Add user context to sentry
          Sentry.setContext("userdetail", {
            ...ctx.rootState.user.data
          });
         
          ctx.commit('init/set', true)
        })
      })
    },
    /** User registration */
    register: async (ctx, { fullname, email, password, country, company, jobPosition, agree }) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      const result = {
        error: null,
        success: false
      }
      let user = null
      try {
        const registerResult = await firebase.auth().createUserWithEmailAndPassword(email, password)
        user = registerResult.user
      } catch (err) {
        Sentry.captureException(err)
        console.log(err)
        if (err.code === 'auth/email-already-in-use')
        result.error = 'registerPage.errors.emailRegistered'
        return result
      }

      await ctx.dispatch('init/next')
      if (!ctx.state.data) {
        console.log('!!!! NO USER NEXT', ctx.state.data, user)
        result.error = 'registerPage.errors.failedUserData'
        return result
      }

      await ctx.dispatch('updateProfile', {
        profile: {
          email: user.email,
          // uid: user.uid,
          locale: ctx.rootState.ui.locale,
          displayName: fullname,
          envLocation: {
            scene: DEFAULT_ENV
          },
          jobPosition,
          company,
          retailerCountry: country,
          tcAgreement: agree
        }
      })
      result.success = true
      return result
    },
    /** User login */
    login: async (ctx, { email, password }) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      try {
        await firebase.auth().signInWithEmailAndPassword(email, password)
      } catch (err) {
        Sentry.captureException(err)
        console.log('login error', err)
        return {
          success: false,
          error: 'loginPage.errors.loginFail',
        }
      }
      return {
        success: true,
        error: null
      }
    },
    /** User logout */
    logout: async ctx => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      // console.log('logout', firebase.auth())
      firebase.auth().signOut()
      ctx.commit('setData', null)
    },
    /** User password restore */
    restorePassword: async (ctx, email) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      try {
        await firebase.auth().sendPasswordResetEmail(email)
      } catch (err) {
        Sentry.captureException(err)
        return { error: 'fail' }
      }
      return { success: true }
    },
    /** Profile update */
    updateProfile: async (ctx, data) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      const ref = firebase.firestore().collection('user').doc(ctx.state.data.uid)
      // console.log('updading profile', data.profile, ref)
      await ref.update(data.profile)
      if (data.passwords?.current.length && data.passwords?.next.length) {
        if (data.passwords.next !== data.passwords.confirm)
          return {
            success: false,
            error: 'userPage.profile.errors.password.notMatch'
          }
        const user = firebase.auth().currentUser
        const credential = firebase.auth.EmailAuthProvider.credential(
          ctx.state.data.email,
          data.passwords.current
        )
        try {
          await user.reauthenticateWithCredential(credential)
        } catch (err) {
          Sentry.captureException(err)
          console.log('reauth error', err)
          return {
            success: false,
            error: 'userPage.profile.errors.password.notValid'
          }
        }
        await user.updatePassword(data.passwords.next)
      }
      return {
        success: true
      }
    },
    /** Update avatar */
    updateAvatar: async (ctx, file) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      if (!file.type.startsWith('image/'))
        return {
          error: 'userPage.profile.errors.avatar.notImage'
        }
      const fileBuff = await file2buffer(file)
      const img = await Jimp.read(fileBuff)
      const buff = await img.getBufferAsync(Jimp.MIME_PNG)
      const ref = firebase.storage().ref(`/user/${ctx.state.data.uid}/avatar.png`)
      await ref.put(buff, { contentType: 'image/png', cacheControl: 'public,max-age=31536000' })
      const url = await ref.getDownloadURL()
      await ctx.dispatch('updateProfile', { profile: { avatar: url } })
      return { success: true }
    },
    /** Delete user's avatar image */
    deleteAvatar: async ctx => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      const ref = firebase.storage().ref(`/user/${ctx.state.data.uid}/avatar.png`)
      await ref.delete()
      await ctx.dispatch('updateProfile', { profile: { avatar: null } })
    },
    /** Upload document */
    uploadDocument: async (ctx, file) => {
      const mimes = {
        [Enums.MIME['text/plain']]: 'text/(markdown|plain)',
        [Enums.MIME['text/csv']]: 'text/csv',
        [Enums.MIME['application/pdf']]: 'application/pdf',
        [Enums.MIME['image/webp']]: 'image/(bmp|gif|jpeg|png|webp)',
        [Enums.MIME['audio/webm']]: 'audio/(aac|mpeg|ogg|wav|webm)',
        [Enums.MIME['video/webm']]: 'video/(mp4|mpeg|ogg|webm)'
      }
      const mimeLine = Object.values(mimes).find(m => {
        const rx = new RegExp(m)
        return rx.test(file.type)
      })
      if (!mimeLine)
        return { error: 'document.mime.unknown' }
      const mimeIndex = Object.values(mimes).indexOf(mimeLine)
 
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      const fileBuff = await file2buffer(file)
      const dbDoc = Object.assign({},Document)
      const docId = genID()
      dbDoc.id = docId
      dbDoc.details = {
        filename: file.name,
        originalFormat: file.type,
        size: file.size,
        url: null
      }
      const now = new Date()
      dbDoc.MIME = parseInt(Object.keys(mimes)[mimeIndex])
      dbDoc.createdBy = ctx.state.data.uid
      dbDoc.createdAt = now
      dbDoc.status = Enums.MediaStatus.Processing
      dbDoc.timestamp = now
      const ref = firebase.app().storage(`gs://${process.env.VUE_APP_FIREBASE_STORAGE_MEDIA_UPLOAD_BUCKET}`).ref(`user/${ctx.state.data.uid}/${mimeIndex}/${docId}`)
      try {
        const opts = {
          contentLanguage: Enums.Locale[ctx.state.data.locale],
          customMetadata: {
            filename: file.name,
            voiceOfGod: file.voiceOfGod ? file.voiceOfGod : 'false'
          },
          cacheControl: 'public,max-age=31536000'
        }
        await ref.put(fileBuff, {
          contentType: file.type,
          ...opts
        })
      } catch (err) {
        Sentry.captureException(err)
        console.log('uploading file error', err)
        return {
          error: 'uploading file error'
        } 
      }
      try {
        await ctx.dispatch('media/save', dbDoc)
      } catch (err) {
        Sentry.captureException(err)
        console.log('media save error', err, dbDoc)
        return {
          error: 'uploading file db error'
        } 
      }
      return { success: true, id: docId }
    },
    /** Upload voice of god document */
    pushVoiceOfGodDocument: async (ctx, file) => {
      const mimeIndex = parseInt(file.type === "audio/mpeg" ? Enums.MIME['audio/webm'] : Enums.MIME['video/webm'])
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      const fileBuff = await file2buffer(file)
      const dbDoc = Object.assign({},Document)
      const docId = genID()
      dbDoc.id = docId
      dbDoc.details = {
        filename: docId,
        originalFormat: file.type,
        size: file.size,
        url: null
      }
      const now = new Date()
      dbDoc.MIME = file.type === "audio/mpeg" ? Enums.MIME['audio/webm'] : Enums.MIME['video/webm']
      dbDoc.createdBy = ctx.state.data.uid
      dbDoc.createdAt = now
      dbDoc.status = Enums.MediaStatus.Processing
      dbDoc.timestamp = now
      //console.log(ctx.state.data.uid);
      const ref = firebase.app().storage(`gs://${process.env.VUE_APP_FIREBASE_STORAGE_MEDIA_UPLOAD_BUCKET}`).ref(`user/${ctx.state.data.uid}/${mimeIndex}/${docId}`)
      try {
        const opts = {
          contentLanguage: Enums.Locale[ctx.state.data.locale],
          customMetadata: {
            filename: docId,
            voiceOfGod: 'true'
          },
          cacheControl: 'public,max-age=31536000'
        }
        await ref.put(fileBuff, {
          contentType: file.type,
          ...opts
        })
      } catch (err) {
        Sentry.captureException(err)
        console.log('uploading file error', err)
        return {
          error: 'uploading file error'
        } 
      }
      try {
        await ctx.dispatch('media/save', dbDoc)
      } catch (err) {
        Sentry.captureException(err)
        console.log('media save error', err, dbDoc)
        return {
          error: 'uploading file db error'
        } 
      }
      return { success: true, id: docId }
    },
    /** Upload voice of god document */
    getVoiceOfGodDocument: (ctx, file) => new Promise((resolve, reject) => {
      ctx.dispatch('pushVoiceOfGodDocument', file)
        .then(res => {
          if (!res?.id) {
            console.log('no doc id?', res)
            reject('document upload error', res)
            return
          }
          ctx.dispatch('media/ref', res.id)
            .then(ref => {
              const sub = ref.onSnapshot(snap => {
                const media = snap.data()
                // console.log('uploaded media', media)
                if (media?.details?.url?.length) {
                  sub()
                  resolve({ media: media.details })
                }
              })
            })
        })
        .catch(err => {
          Sentry.captureException(err)
          console.log('uploadMedia error!', err)
          reject(err)
        })
    }),
    /** Upload document */
    uploadCKMedia: (ctx, file) => new Promise((resolve, reject) => {
      ctx.dispatch('uploadDocument', file)
        .then(res => {
          if (!res?.id) {
            console.log('no doc id?', res)
            reject('document upload error', res)
            return
          }
          ctx.dispatch('media/ref', res.id)
            .then(ref => {
              const sub = ref.onSnapshot(snap => {
                const media = snap.data()
                // console.log('uploaded media', media)
                if (media?.details?.url?.length) {
                  sub()
                  resolve({ default: media.details.url })
                }
              })
            })
        })
        .catch(err => {
          Sentry.captureException(err)
          console.log('uploadMedia error!', err)
          reject(err)
        })
    }),
    /** set user locale */
    setLocale: async (ctx, next) => {
      if (typeof Enums.Locale[next] === 'undefined') return
      await ctx.dispatch('updateProfile', { profile: { locale: next }})
    },
    /** submit activity score */
    submitActivityScore: async (ctx, score) => {
      await ctx.dispatch('score/save', score)
    },
    /** save media to briefcase */
    bookmarkMedia: async (ctx, info) => {
      const doc = JSON.parse(JSON.stringify(info.media))
      doc.activity = info.activity
      doc.isDownloadable = !!info.isDownloadable
      await ctx.dispatch('media/save', doc)
    },
    /** Set Sentry context with user details */

  }
}

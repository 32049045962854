<script>
export default {
  name: "DevTools",

  components: {},
  data: () => ({}),
  methods: {
    throwTestError() {
      console.log("Test error..");
      throw new Error("Sentry Test Error");
    },
  },

  setup() {
    return {};
  },
};
</script>

<template>
  <v-app>
    <v-main>
      <v-card class="mx-auto my-12" max-width="374">
        <v-card-title>Developer Tools</v-card-title>

        <v-card-text>
          <v-btn block @click="throwTestError"> Throw Test Sentry Error </v-btn>
        </v-card-text>
      </v-card>
    </v-main>
  </v-app>
</template>

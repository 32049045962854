import store from '@/store'
import can from '@/utils/can'
import iam from '@/utils/iam'

import { Locale } from '@/lib/enums'

const mixins = {
  methods: {
    $can: action => can(store.state, action),
    $iam: role => iam(store.state, role),
    $l: loc => loc 
      ? loc[store.state.ui.locale]
        ? loc[store.state.ui.locale]
        : loc[Locale.en]
          ? loc[Locale.en]
          : Object.values(loc).find(a => a && a.length)
            ? Object.values(loc).find(a => a && a.length)
            : ''
      : ''
  }
}

export default mixins

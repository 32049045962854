import { UserRole } from '@/lib/enums'

/**
  Role.ROOT grants full access automatically
  Roles in list ar OR checked
*/

export const rights = {
  root: [],
  // some pages are only accessible by "guests"
  guest: [UserRole.Guest],
  // users_management right requires to change user's roles, accept or reject new registrations at administration dashboard
  users_management: [],
  // admin: [ UserRole.Trainer, UserRole.CommunityManager ],
  admin: [],
  // countdown: [ UserRole.CorporatePartner ],
  countdown: [],
  authorized: [ UserRole.Trainer, UserRole.CommunityManager, UserRole.BrandInfluencer, UserRole.RetailSalesAssociate, UserRole.FacebookEmployee, UserRole.CorporatePartner ]
}

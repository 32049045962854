import { createRouter, createWebHistory } from "vue-router";

import { trackRouter } from "vue-gtag-next";

import HomePage from "@/views/Home.vue";
import NotFoundPage from "@/views/2d/NotFound.vue";
import DevTools from "@/views/DevTools.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage,
    
  },
  {
    path: "/devtools",
    name: "Dev Tools",
    component: DevTools,
    meta: {
      access: "guest",
      noSplash: true,
    },
  },
  {
    path: "/countdown",
    name: "CountdownPage",
    component: () =>
      import(/* webpackChunkName: "guest2d" */ "@/views/2d/Countdown.vue"),
    meta: {
      //access: 'guest',
      noSplash: true,
    },
  },
  {
    path: "/account-under-review",
    name: "AccountUnderReviewPage",
    component: () =>
      import(
        /* webpackChunkName: "guest2d" */ "@/views/2d/AccountUnderReview.vue"
      ),
    meta: {
      access: "guest",
      noSplash: true,
    },
  },
  {
    path: "/account-rejected",
    name: "AccountRejectedPage",
    component: () =>
      import(
        /* webpackChunkName: "guest2d" */ "@/views/2d/AccountRejected.vue"
      ),
    meta: {
      access: "guest",
      noSplash: true,
    },
  },
  {
    path: "/not-supported/:reason(browser|mobile|tablet|webgl)",
    name: "NotSupportedPage",
    component: () =>
      import(/* webpackChunkName: "guest2d" */ "@/views/2d/NotSupported.vue"),
    meta: {
      noSplash: true,
    },
  },
  {
    path: "/error",
    name: "ErrorPage",
    component: () =>
      import(/* webpackChunkName: "guest2d" */ "@/views/2d/Error.vue"),
    meta: {
      noSplash: true,
    },
  },
  {
    path: "/login",
    name: "LoginPage",
    component: () =>
      import(/* webpackChunkName: "guest2d" */ "@/views/2d/Login.vue"),
    meta: {
      noSplash: true,
    },
  },
  {
    path: "/register",
    name: "RegisterPage",
    component: () =>
      import(/* webpackChunkName: "guest2d" */ "@/views/2d/Register.vue"),
    meta: {
      noSplash: true,
    },
  },
  {
    path: "/forgot-password",
    name: "ForgotPasswordPage",
    component: () =>
      import(/* webpackChunkName: "guest2d" */ "@/views/2d/ForgotPassword.vue"),
    meta: {
      noSplash: true,
    },
  },
  {
    path: "/about",
    name: "AboutPage",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
    meta: {
      noSplash: true,
    },
  },
  {
    path: "/3d/:scene(store|office|warehouse|campus|pool|loft|apartment)",
    name: "3DPage",
    component: () => import(/* webpackChunkName: "3d" */ "@/views/3D.vue"),
    meta: {
      access: "authorized",
      touchRequired: true,
    },
    children: [
      {
        path: "a/:id",
        name: "ActivityOverlay",
        components: {
          overlayUi: () =>
            import(/* webpackChunkName: "3d" */ "@/views/ActivityOverlay.vue"),
        },
      },
      {
        path: "teams",
        name: "Teams",
        components: {
          overlayUi: () =>
            import(/* webpackChunkName: "3d" */ "@/views/Teams.vue"),
        },
      },
      {
        path: "settings",
        name: "SettingsPage",
        components: {
          overlayUi: () =>
            import(/* webpackChunkName: "3d" */ "@/views/Settings.vue"),
        },
      },
      {
        path: "schedule",
        name: "3DSchedulePage",
        components: {
          overlayUi: () =>
            import(/* webpackChunkName: "3d" */ "@/views/3dScedulePage.vue"),
        },
      },
      {
        path: "user",
        name: "UserPage",
        components: {
          overlayUi: () =>
            import(/* webpackChunkName: "3d" */ "@/views/user/User.vue"),
        },
        children: [
          {
            path: "profile",
            name: "Profile",
            components: {
              profileView: () =>
                import(/* webpackChunkName: "3d" */ "@/views/user/Profile.vue"),
            },
          },
          {
            path: "files/:type?",
            name: "Files",
            components: {
              profileView: () =>
                import(/* webpackChunkName: "3d" */ "@/views/user/Files.vue"),
            },
          },
          {
            path: "gallery",
            name: "Gallery",
            components: {
              profileView: () =>
                import(/* webpackChunkName: "3d" */ "@/views/user/Gallery.vue"),
            },
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    component: () =>
      import(/* webpackChunkName: "admin" */ "@/views/admin/Dashboard.vue"),
    meta: {
      access: "admin",
      adminStore: true,
      noSplash: true,
    },
    children: [
      {
        path: "",
        redirect: {
          name: "AdminUsers",
        },
      },
      {
        path: "users/:filter?",
        name: "AdminUsers",
        components: {
          dashboardPage: () =>
            import(/* webpackChunkName: "admin" */ "@/views/admin/Users.vue"),
        },
        meta: {
          pageTitle: "users",
        },
      },
      {
        path: "users-whitelists",
        name: "AdminUsersWhiteLists",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/UsersWhitelists.vue"
            ),
        },
        meta: {
          pageTitle: "usersWhitelists",
          access: "users_management",
        },
      },
      {
        path: "scheduling",
        name: "Scheduling",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/Scheduling.vue"
            ),
        },
        meta: {
          pageTitle: "scheduling",
        },
      },
      {
        path: "email",
        name: "AdminEmail",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/email/EmailPage.vue"
            ),
        },
        meta: {
          pageTitle: "email",
        },
        children: [
          {
            path: "create",
            name: "EmailCreate",
            components: {
              emailUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/email/EmailCreate.vue"
                ),
            },
            meta: {
              pageTitle: "emailcreate",
            },
          },
          {
            path: "activity-invitation",
            name: "ActivityInvitationEmail",
            components: {
              emailUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/email/ActivityInvitationEmail.vue"
                ),
            },
            meta: {
              pageTitle: "activityInvitationEmail",
            },
          },
          {
            path: "activity-followup",
            name: "ActivityFollowupEmail",
            components: {
              emailUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/email/ActivityFollowupEmail.vue"
                ),
            },
            meta: {
              pageTitle: "activityFollowupEmail",
            },
          },
        ],
      },
      {
        path: "voice-of-god/:filter?",
        name: "AdminVoiceOfGod",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/VoiceOfGod.vue"
            ),
        },
        meta: {
          pageTitle: "voiceOfGod",
        },
      },
      {
        path: "analytics",
        name: "Analytics",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/Analytics.vue"
            ),
        },
        meta: {
          pageTitle: "analytics",
        },
      },
      {
        path: "scorm-manager",
        name: "ScormManager",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/ScormManager.vue"
            ),
        },
        meta: {
          pageTitle: "scormManager",
        },
      },
      {
        path: "touch-points",
        name: "TouchPoints",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/TouchPoints.vue"
            ),
        },
        meta: {
          pageTitle: "touchpoints",
        },
      },
      {
        path:
          "touch-point/:scene(store|office|warehouse|campus|pool|loft|apartment)",
        name: "SceneTouchPoints",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/SceneTouchpoints.vue"
            ),
        },
        meta: {
          pageTitle: "sceneTouchpoints",
        },
      },
      {
        path: "quiz-editor",
        name: "AdminQuizEditor",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/quiz-editor/QuizPage.vue"
            ),
        },
        meta: {
          pageTitle: "quizes",
        },
        children: [
          {
            path: "quizes",
            name: "QuizesList",
            components: {
              quizUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/quiz-editor/QuizesList.vue"
                ),
            },
            meta: {
              pageTitle: "quizesList",
            },
          },
          {
            path: "quiz/:id?",
            name: "QuizEditor",
            components: {
              quizUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/quiz-editor/QuizEditor.vue"
                ),
            },
            meta: {
              pageTitle: "quizEditor",
            },
          },
          {
            path: "create-quiz",
            name: "QuizCreate",
            components: {
              quizUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/quiz-editor/QuizCreate.vue"
                ),
            },
            meta: {
              pageTitle: "quizCreate",
            },
          },
        ],
      },
      {
        path: "activity-editor",
        name: "AdminActivityEditor",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/activity/ActivityPage.vue"
            ),
        },
        meta: {
          pageTitle: "activitiesList",
        },
        children: [
          {
            path: "activities",
            name: "Activities",
            components: {
              activityUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/activity/ActivitiesList.vue"
                ),
            },
            meta: {
              pageTitle: "activitiesList",
            },
          },
          {
            path: "create-activity",
            name: "ActivityCreate",
            components: {
              activityUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/activity/ActivityCreate.vue"
                ),
            },
            meta: {
              pageTitle: "activityCreate",
            },
          },
          {
            path: "activity/:id",
            name: "ActivityEditor",
            components: {
              activityUI: () =>
                import(
                  /* webpackChunkName: "admin" */ "@/views/admin/activity/ActivityEditor.vue"
                ),
            },
            meta: {
              pageTitle: "activityEditor",
            },
          },
        ],
      },
      {
        path: "settings",
        name: "AdminSettings",
        components: {
          dashboardPage: () =>
            import(
              /* webpackChunkName: "admin" */ "@/views/admin/Settings.vue"
            ),
        },
        meta: {
          pageTitle: "settings",
        },
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    catchAll: true,
    name: "NotFound",
    component: NotFoundPage,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// Auto track routes in vue-gtag-next
trackRouter(router);

// By default the plugin tracks page_view events, but it's possible to enable screen_view instead by setting useScreenview to true
// trackRouter(router, {
//   useScreenview: true
// });

export default router;

/**
  checks if user has right for some action according to @/rights.js
*/
import { UserRole } from '@/lib/enums'
import { rights } from '@/rights'
import iam from '@/utils/iam'

const can = (state, action) => {
  if (iam(state, UserRole.Root))
    return true
  if (!rights[action])
    return false
  return rights[action].some(role => iam(state, role))
}

export default can

/**
  Init store provides simple flag/subscribption for initialization routine
*/

export default {
  namespaced: true,
  state: () => ({
    done: false,
    handler: null
  }),
  mutations: {
    setHandler: (state, next) => { state.handler = next },
    set: (state, next) => {
      state.done = next
      if (state.handler) {
        state.handler(next)
        state.handler = null
      }
    }
  },
  actions: {
    next: ctx => new Promise(accept => {
      if (ctx.state.done)
        accept(true)
      else
        ctx.commit('setHandler', accept)
    })
  }
}

<script>
import { useI18n } from 'vue-i18n'
import Logo from '@/assets/imgs/admin-logo.svg'

export default {
  name: 'Splash',
  components: {
    Logo
  },
  setup() {
    const { t } = useI18n()
    return { t }
  },
  watch: {
    '$store.state.pc.splash': {
      deep: true,
      handler(next) {
        if (next?.video?.length && this.$refs.video) {
          // console.log('splash!', next.video, this.$refs.video)
          this.$refs.video.load()
        }
        // console.log('next splash!', this.$store.state.splash, this.$refs.video, next)
      }
    }
  },
  computed: {
    showLogo() {
      const splash = this.$store.state.pc.splash
      if (!splash) return false
      if (!splash.video) return true
      return splash.video === '' ? true : this.$refs.video.readyState !== 4
    }
  }
}
</script>

<template>
  <div id="app-splash" class="application-splash-wrapper bg-white" :class="{ hide: !$store.state.pc.splash }">
    <Logo class="splash-logo" v-if="showLogo" :alt="t('shared.facebookHackersAcademy')"/>
    <!--
    <Logo class="splash-logo" v-if="$store.state.pc.splash?.video === ''" :alt="t('shared.facebookHackersAcademy')"/>
    -->
    <v-btn
      size="large"
      color="fb-navy"
      v-if="$store.state.pc.splash?.touch"
      class="enter-button"
      @click="$store.commit('setTouch', true); $store.commit('pc/setSplash', null)"
    >
      {{$t('buttons.enterButton')}}
    </v-btn>
    
    <div class="splash-video" v-if="$store.state.pc.splash?.video !== ''">
      <video @contextmenu.stop.prevent ref="video" autoplay loop>
        <source :src="`/loader/${$store.state.pc.splash?.video}.mp4`" type="video/mp4"/>
      </video>
    </div>
    <!-- <v-btn
      v-if="$store.state.pc.splash?.skip"
      class="skip-button"
      @click="$store.commit('pc/setSplash', null)"
    >
      {{t('buttons.skip')}}
    </v-btn> -->
    <div v-if="$store.state.pc.splash?.loader" class="splash-loader">
      <div class="progress-bar" ref="progressBar" :style="{ width: $store.state.pc.load+'%'}"></div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@keyframes fadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
}
#app-splash {
  z-index: 20000;

  &.hidden {
    visibility: hidden ;
    animation: fadeOut 1s;
  }
}


.application-splash-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ACD7DD;
  opacity: 1;
  visibility: visible;
  transition: opacity 500ms;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }

  .splash-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;

    video {
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .splash-logo {
    position: absolute;
    top: 50%;
    width: calc(1.5 *150px);
    height: calc(1.5 * 45px);
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    text-align: center;
    img {
      width: 100%;
    }
  }

  .splash-loader {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0px;
    // margin: 20px auto 0 auto;
    height: 4px;
    width: 100%;
    background-color: #1d292c;
    .progress-bar {
      width: 0%;
      height: 100%;
      background-color: #1E84ED;
    }
  }

  .enter-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 45px);
  }

  .skip-button {
    position: absolute;
    bottom: 30px;
    right: 30px;
    background: none;
    border: 0;
    color: white;
  }
}
@media (max-width: 480px) {
  .application-splash {
    width: 170px;
    left: calc(50% - 85px);
  }
}
</style>

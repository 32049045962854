const directives = {
  // sourced from https://stackoverflow.com/questions/63869859/detect-click-outside-element-on-vue-3
  'click-outside': {
    beforeMount(el, binding, ) {
      el.clickOutsideEvent = function (event) {
        if (!(el === event.target || el.contains(event.target))) {
          binding.value(event, el)
        }
      }
      document.body.addEventListener('click', el.clickOutsideEvent)
    },
    unmounted(el) {
      document.body.removeEventListener('click', el.clickOutsideEvent)
    },
  },
}

export default directives

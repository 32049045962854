import { Locale } from '@/lib/enums.js'
import env from '@/utils/env'

// console.log('ui locale?', localStorage.getItem('ui/locale'), env.locale, Locale[env.locale])
export default {
  namespaced: true,
  state: {
    locale: localStorage.getItem('ui/locale')
              ? Locale[localStorage.getItem('ui/locale')]
              : Locale[env.locale], // auto detected from browser and supported locales
    busy: false,
    open: false,
    load2d: false,
    load3d: false,
    error: null,
    errorLink: null,
    hasMedia: false,
    volume: {
      music: localStorage.getItem('ui/volume/music')
              ? parseInt(localStorage.getItem('ui/volume/music'))
              : 25,
      player: localStorage.getItem('ui/volume/player')
              ? parseInt(localStorage.getItem('ui/volume/player'))
              : 50,
      lastMusic: localStorage.getItem('ui/volume/music')
              ? parseInt(localStorage.getItem('ui/volume/music'))
              : 50,
    },
    leaderboard: 'global',
    leaderboardOpen: false,
    videoNotification: null
  },
  mutations: {
    setVideoNotification: (state, next) => {
      state.videoNotification = next
    },
    pausePlayer: state => {
      state.volume.lastMusic = state.volume.music
      state.volume.music = 0
    },
    unpausePlayer: state => {
      state.volume.music = state.volume.lastMusic
    },
    setBusy: (state, next) => {
      state.busy = next
    },
    setOpen: (state, next) => {
      state.open = next
    },
    setLoad2D: (state, next) => {
      state.load2d = next
    },
    setLoad3D: (state, next) => {
      state.load3d = next
    },
    setError: (state, { error, errorLink = null }) => {
      state.error = error
      state.errorLink = errorLink
    },
    setLocale: (state, locale) => {
      if (typeof Locale[locale] === 'undefined') return
      if (Locale[locale] === state.locale ) return
      if (state.locale === locale) return
      state.locale = Locale[locale]
      localStorage.setItem('ui/locale', locale)
    },
    setHasMedia: (state, next) => {
      state.hasMedia = next
    },
    setVolume: (state, { code, val }) => {
      state.volume[code] = val
      localStorage.setItem(`ui/volume/${code}`, val)
    },
    setLeaderboard: (state, next) => {
      state.leaderboard = next || 'global'
      state.leaderboardOpen = false
    },
    setLeaderboardOpen: (state, next) => {
      state.leaderboardOpen = next
    }
  },
  actions: {
    initialize: ctx => {
      // console.log(`init volumes music: ${ctx.state.volume.music}% player: ${ctx.state.volume.player}%`)
      if (isNaN(ctx.state.volume.music) || ctx.state.volume.music < 5)
        ctx.commit('setVolume', { code: 'music', val: 5 })
      if (isNaN(ctx.state.volume.player) || ctx.state.volume.player < 5)
        ctx.commit('setVolume', { code: 'player', val: 5 })
    },
    popupError: (ctx, { error, errorLink = null }) => {
      ctx.commit('setError', { error, errorLink })
      setTimeout(() => {
        ctx.commit('setError', { error: null })
      }, 3000)
    }
  }
}

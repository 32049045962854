import * as Bowser from 'bowser'
import { Locale } from '@/lib/enums.js'
 
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const localeParam = urlParams.get('locale') || null

const supportedBrowserLangs = navigator.languages.filter(nlng => typeof Locale[nlng] !== 'undefined')

const locale = localeParam || supportedBrowserLangs[0] || 'en'

const browser = Bowser.parse(navigator.userAgent)

export default {
  locale,
  ...browser
}

export default {
  namespaced: true,
  state: {
    app: null,
    scene: null,
    splash: {
      loader: false,
      video: '',
      skip: false,
      touch: false
    },
    load: 0
  },
  mutations: {
    setApp: (state, next) => {
      state.app = next
    },
    setScene: (state, next) => {
      state.scene = next
    },
    setSplash: (state, next) => {
      state.splash = next
    },
    setSkip: (state, next) => {
      if (state.splash)
        state.splash.skip = next
    },
    setLoad: (state, next) => {
      state.load = next
    },
    fire: (state, {code, data}) => {
      if (!state.app) return
      state.app.$emit(code, data)
    }
  },
  actions: {}
}

//using require to get translation scripts working
const { UserRole, ActivityType, Locale, Scene /*, JobPosition*/ } = require('../lib/enums')
// ActivityType[7] = 'Scorm'
// ActivityType.Scorm = 7

const en = {
  /** user roles */
  roles: {
    placeholder: 'Role',
    [UserRole.Guest]: 'Guest',
    [UserRole.Root]: 'Admin',
    [UserRole.Trainer]: 'Trainer',
    [UserRole.CommunityManager]: 'Community Manager',
    [UserRole.BrandInfluencer]: 'Brand Influencer',
    [UserRole.RetailSalesAssociate]: 'Retail Sales Associate',
    [UserRole.FacebookEmployee]: 'Facebook Employee',
    [UserRole.CorporatePartner]: 'Corporate Partner',
  },
  /** locales */
  locales: {
    placeholder: 'Locale',
    [Locale.en]: 'English',
    [Locale.es]: 'Español',
    [Locale.fr]: 'Français'
  },
  /** scenes */
  scenes: {
    placeholder: 'Scene',
    [Scene.Apartment]: "Apartment",
    [Scene.Campus]: "Campus",
    [Scene.Loft]: "Loft",
    [Scene.Office]: "Office",
    [Scene.Pool]: "Pool",
    [Scene.Store]: "Store",
    [Scene.Warehouse]: "Warehouse",
  },
  /** countries names by <enum::RetailerCountry> */
  countries: {
    US: 'USA',
    FR: 'France',
    ES: 'Spain',
    GB: 'UK'
  },
  /** job positions */
  /*
  jobPositions: {
    placeholder: 'Position',
    [JobPosition.CM]: 'CM',
    [JobPosition.BM]: 'BM',
    [JobPosition.RSA]: 'RSA',
  },
  /** Commonly used buttons */
  buttons: {
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
    add: 'Add',
    save: 'Save',
    update: 'Update',
    delete: 'Delete',
    cancel: 'Cancel',
    clear: 'Clear',
    upload: 'Upload',
    selectFile: 'Select File',
    replace: 'Replace',
    skip: 'Skip',
    next: 'Next',
    close: 'Close',
    submit: 'Submit',
    sendEmail: "Send Email",
    enterButton: "Enter",
    start: 'Start',
    send: 'Send',
    uploadImage: 'Upload Image',
    useWebcame: "Use Webcam",
    capture: "Capture",
    reupload: "Re-upload",
    retake: "Retake",
    finish:"Finish",
    joinTeams:"Join",
  },
  /** Text */
  narrowBrowser: "Looks like your browser is very narrow!<br/> Please make your browser bigger to play around.",
  hoverLabels: {
    eventSchedule: "Event Schedule",
    leaderBoard: "Leaderboard",
    audioSetting: "Audio Setting",
    settings: "Settings",
    chat: "Chat",
    videoChat: "Video Chat",
    profile: "Profile",
    notification: "Notifications",
    addToBriefcase: "Add to briefcase",
    alreadyInBriefcase: "Remove from briefcase",
    download: "Download",
    addTocalendar: "Add to calendar",
  },
  calendar: {
    timezone: 'Time zone',
    timezones: {
      LOCAL: 'Local',
      PST: 'Pacific',
      CET: 'Europe'
    },
    from:"From",
    to:"To",
    speakers: "Speakers",
    scene: "Scene",
    noEvents: "There is no Events for",
    sendToTeamsNote: "This session will take place in MS Teams. We would love to see you back on the platform when the MS Teams meeting is over!"
  },
  /** Session Map */
  sessionMap:{
    title:"Click on your assigned session below to join the interactive Teams call.",
    dayOne:{
      title:"Day 1",
      boxOne:{
        title:"EMEA: Essential Skill Training",
        buttonOne:"France",
        buttonTwo:"UK"
      },
      boxTwo:{
        title:"EMEA: New Product Q&A",
        buttonOne:"France & UK",
      },
      boxThree:{
        title:"US: Essential Skills Training",
        buttonOne:"US",
      }
    },
    dayTwo:{
      title:"Day 2",
      boxOne:{
        title:"EMEA: Teachbacks",
        buttonOne:"France",
        buttonTwo:"UK"
      },
      boxTwo:{
        buttonOne:"Facebook Q&A",
      },
      boxThree:{
        title:"US: Teachbacks",
        buttonOne:"CM East",
        buttonTwo:"CM West",
        buttonThree:"CM central",
        buttonFour:"BI Training Room 1",
        buttonFive:"BI Training Room 2",
        buttonSix:"BI Training Room 3",
      }
    }
  },
  /** Shared lines */
  shared: {
    facebookHackersAcademy: 'Facebook Hackers Academy'
  },
  /** countdown page */
  countdownPage: {
    title: 'Countdown'
  },
  /** 2D Not supported page */
  notSupportedPage: {
    title: {
      browser: 'Browser error',
      mobile: 'Mobile error',
      tablet: 'Tablet error',
      webgl: 'WebGL error'
    },
    text: {
      browser: 'Your browser is not supported.<br/>We recommend to use <a href="https://www.google.com/chrome/">chrome</a> or <a href="https://www.mozilla.org/firefox/">firefox</a> latest version',
      mobile: 'Mobile support is disable.<br/>Please use desktop.',
      tablet: 'Tablet support is disable.<br/>Please use desktop.',
      webgl: 'WebGL is not supported.<br/>Please check your OS and browser.'
    }
  },
  /** 2D 404 - Not Found page */
  notFoundPage: {
    title: '404',
    labels: {
      notFound: 'Page not found'
    }
  },
  /** 2D Login page */
  loginPage: {
    title: 'Login',
    fields: {
      email: 'Email address',
      password: 'Password',
    },
    buttons: {
      forgot: 'Forgot Password?',
      login: 'Login',
      register: 'Register'
    },
    labels: {
      or: 'or'
    },
    errors: {
      loginFail: 'Your email or password is incorrect. Please try again.'
    },
    admin: {
      title: 'Select page',
      admin: 'Administration dashboard',
      campus: 'Campus scene'
    }
  },
  /** 2D Re-enter Page */
  reEnterPage: {
    title: 'Re-enter Here',
    welcomeMessage: "Welcome back to Hacker's Acedemy!",
    agreeMessage: "I agree to the",
    termAndConditions: "term and conditions"
  },
  /** 2D Register page */
  registerPage: {
    title: 'Register Here',
    fields: {
      name: 'Full name',
      email: 'Email Address',
      password: 'Password',
      password2: 'Confirm Password',
      country: 'Country Residence',
      company: 'Company',
      companyOther: 'other (enter)',
      jobPosition: 'Position',
      agree: 'I agree to the',
      termsAndConditions: 'terms and conditions'
    },
    buttons: {
      register: 'Register',
      signIn: 'Sign In'
    },
    labels: {
      welcome: 'Welcome to the Hacker\'s Academy! Register here and discover this new experience.',
      hasAccount: 'Already have an account?'
    },
    errors: {
      passwordsNotMatch: 'Password confirmation doesn\'t match password',
      emailRegistered: 'Email already registered'
    }
  },
  /** 2D Restore password page */
  restorePasswordPage: {
    title: 'Restore Password',
    fields: {
      email: 'Email Address'
    },
    labels: {
      success: 'Check your email',
      fail: 'Email not found',
      or: 'or'
    },
    buttons: {
      restore: 'Restore',
      signIn: 'Sign In'
    }
  },
  /** 2D Error page */
  errorPage: {
    title: 'Error'
  },
  /** 2D Wait for account confirmation page */
  waitConfirmPage: {
    title: 'Account is under review',
    message: 'Please wait for confirmation email. Thanks.'
  },
  /** 2D Rejected/banned accounts page */
  rejectPage: {
    title: 'Account is rejected',
    message: 'Your account was rejected. Thanks.'
  },
  // User page
  userPage: {
    sidebarLinks: {
      profile: {
        label: "Profile",
        supportText: "Edit your profile"
      },
      files: {
        label: "Briefcase",
        supportText: "Download files"
      },
      gallery: {
        label: "Gallery",
        supportText: "photos"
      },
      logout: {
        label: "Log Out",
        supportText: "close session"
      }
    },
    profile:{
      title : "Profile",
      passwordTitle: "Change password",
      fields: {
        photo: {
          label : "Photo",
          deleteLabel : "Delete photo"
        },
        name: {
          label : "Name",
          placeholder : "Enter Name"
        },
        email: {
          label : "Email",
          notice: "email cannot be changed"
        },
        country: {
          label : "Country",
          placeholder : "Choose Country"
        },
        company: {
          label : "Company",
          placeholder : "Enter Company Name"
        },
        passwordCurrent: {
          label : "Current Password",
          placeholder : "Enter your current password"
        },
        password: {
          label : "New password",
          placeholder : "Enter new password"
        },
        password2: {
          label : "Confirm new password",
          placeholder : "Confirm new password"
        }
      },
      errors: {
        password: {
          notValid: 'Password not valid',
          notMatch: 'New password and confirmation not match'
        }
      }
    },
    files:{
      title : "Briefcase",
      types:{
        all : "All",
        documents : "Documents",
        audio : "Audio",
        video : "Video",
        image : "Image",
        score : "Score",
      },
      score: {
        greetings: 'Great Play!',
        table: {
          heading:{
            name : "Name",
            score : "Score",
            finishing_time : "Finishing Time",
          }
        }
      }
    },
    gallery:{
      title : "Gallery"
    }
  },
  activity: {
    map: {
      levelX: 'Level {num}'
    },
    scorm: {
      resultMessage: 'Your final result is {points}'
    },
    quiz: {
      questionX: 'Question #{num}:',
      resultScore: 'Result score <br/> {score}',
      underConstruction: 'Quiz is under construction<br/>Try again later or call to administrator.',
      maxGamesReached: 'You already played this Quiz.<br/>Maximum allowed: {max}',
      maxGamesOne: 'You can play one time! | You can play {max} times more.',
      maxGamesMore: 'You can play {max} times more.',
    },
    leaderBoardEmpty: 'Leaderboard is empty',
    leaderBoardHelp: 'The leaderboard points are calculated from a total of your quiz results & easter eggs'
  },
  settingsPage: {
    locale: 'Language',
    dashboard: 'Administration dashboard'
  },
  // ERRORS
  error: {
    labels: {
      readMore: 'Read more'
    },
    unknown: 'Unknown critical error happened',
    webGL: {
      unsupportedBrowser: 'This browser does not support WebGL',
      unsupportedDevice: 'This device does not support WebGL'
    },
    playcanvas: {
      initialization: 'Error happens on 3D initialization',
      preloadAssets: 'Error happens on 3D initialization',
      loadScene: 'Error happens on 3D initialization'
    }
  },
  // Admin
  admin: {
    sidebarLinks: {
      users: "Users",
      scheduling: "Scheduling",
      email: "Email",
      voiceOfGod: "Voice of God",
      analytics: "Analytics",
      touchPoints: "Touch Points",
      quizes: "Quizzes",
      activityEditor: "Activity Editor",
      scormManager: "SCORM Manager",
      settings: "Settings",
      logout: "Logout",
    },
    pageTitle: {
      users: 'Users',
      usersWhitelists: "White lists",
      scheduling: 'Schedules',
      email: 'Emails',
      sentEmails: "Sent emails",
      emailcreate: 'New Email',
      voiceOfGod: 'Voice of God',
      analytics: 'Analytics',
      touchpoints: 'Scenes',
      sceneTouchpoints: 'Touch Points',
      scormManager: 'SCORM Manager',
      quizes: 'Quiz Editor',
      quizesList: 'Quizzes',
      quizEditor: 'Quiz Editor',
      quizCreate: 'Create Quiz',
      activityEditor: "Activity Editor",
      activityCreate: "Create new Activity",
      activitiesList: "Activities List",
      settings: "Settings",
      activityInvitationEmail: "Calendar Invitation",
      activityFollowupEmail: "Followup Email"
    },
    scormManager: {
      dialog: {
        deleteTitle: 'Delete?',
        deleteMessage: 'File <{name}> will be lost!'
      }
    },
    whiteListPage: {
      addToList: 'Add new rule',
      email: 'Email',
      approved: 'Approved',
      role: 'Role',
      csv: {
        errTitle: 'Failed!',
        errMessage: 'File reading error: {error}',
      },
      delTitle: 'Delete?',
      delMessage: 'Delete rule for <{email}>?',
      failTitle: 'Failed',
      userExists: 'User {email} already registered',
      ruleExists: 'User {email} already in list'
    },
    usersPage: {
      position: '{position} at {company}',
      modes: {
        registered: 'Registered users',
        guests: 'Guests users',
        rejected: 'Rejected users',
        whitelists: "White lists",
      },
      viewMode: {
        list: 'List',
        grid: 'Grid'
      },
      sort: {
        avatar: '',
        displayName: 'Name',
        email: 'Email',
        phone: 'Phone',
        retailerCountry: 'Country / Position',
        role: 'Role'
      },
      actions: {
        ban: 'Ban',
        reject: 'Reject',
      },
      paginator: {
        itemspp: 'Items per page'
      },
      dialogs: {
        confirmRoleTitle: 'Change user role?',
        confirmRoleMessage: 'Set {user} as {role}?',
        confirmBanTitle: 'Ban user?',
        confirmBanMessage: 'User {user} will be banned at system.'
      }
    },
    schedulingPage: {
      monthSwiper: {
        label: "Today"
      },
      createCalenderEvent:{
        title:"Create Calender Event",
        titleEdit:"Update Calender Event",
        deleteDialog: {
          title: 'Delete?',
          message: 'Delete <{name}>?'
        },
        fields:{
          name: "Name",
          primary: "Is Live",
          break: "Break",
          description: "Description",
          locale: "Locale",
          scene: "Scene",
          teamsUrl: "Teams URL",
          speakers: "Speakers",
          video: "Video",
          starttime: "Start Time",
          endtime: "End Time",
        }
      }
    },
    emailPage: {
      sidebar:{
        labels:{
          mailbox : "Mailbox",
          labels : "Labels"
        }
      },
      labels:{
        urgent: "Urgent",
        work: "Work",
        friends: "Friends",
        family: "Family",
        webinars: "Webinars",
      },
      sidebarLinks:{
        newMessage : "New Message",
        sent : "Sent",
        sendActivityInvitation: "Calendar Invitation",
        sendActivityFollowupInvitation: "Followup Email"
      },
      createEmailPage:{
        fields:{
          user:{
            label: "User",
            placeholder: "Choose Users"
          },
          headerImage:{
            label: "Upload header image",
            placeholder: "Upload header image"
          },
          footerImage:{
            label: "Upload footer image",
            placeholder: "Upload footer image"
          },
          pdfUpload:{
            label: "Upload Pdf",
            placeholder: "Upload pdf"
          },
          subject:{
            label: "Subject",
            placeholder: "Write Subject"
          },
          labels:{
            label: "Label",
            placeholder: "Choose Labels"
          },
          message:{
            label: "Message"
          }
        },
        writeCustomEmail: "write email and hit enter to add custom email",
        successMessage: "Email sent successfully",
        successMessageForInvitation: "invitation sent successfully",
        faildinvitees: "Fails to send invite to following users",
        alreadyInvited: "Following users is already Invited",
        errorMessage: "Something went wrong! please try again"
      }
    },
    voiceOfGodPage: {
      sidebar:{
        labels:{
          talkTo : "Talk To",
        }
      },
      sidebarLinks:{
        all: "All",
        brandInfluencer: "Brand Influencer",
        communityManager: "Community Manager",
        retailSalesAssociate: "Retail Sales Associate",
        trainer: "Trainer"
      },
      userSection: {
        noData: "No user found"
      },
      startRecording: "Start Recording",
      finishRecording: "Finish Recording",
      sendNotification: "Send Notification",
      notificationTitle: "Notification Title",
      immidiatly: "Play Immediately",
      noUserSelectedWarning: "select at least one user to send notification",
      labels:{
        vog: "Voice Of God",
      },
      sectionTalk: {
        title: "Talk",
        time: "Time",
      },
      sectionLive: {
        title: "Live"
      },
      sectionWrite: {
        title: "Write"
      },
      buttonLabels:{
        mute:"Mute",
        talk: "Talk",
        mark: "Mark",
        shareScreen: "Share Screen",
        startVideo: "Start Video"
      }
    },
    touchPointsPage:{
      touchPoints:{
        appartment: "Appartment",
        campus: "Campus",
        loft: "Loft",
        office: "Office",
        pool: "Pool",
        store: "Store",
        warehouse: "Warehouse",
      },
      labels: {
        none: 'None',
        touchPoints: 'Touch Points',
        endOfSceneQuiz: 'End of Scene Quiz'
      }
    },
    quizesPage:{
      quizEditor:{
        sidebar:{
          title: "Questions",
          images: "Images",
          rules: "Rules",
          mainInfo: "Main Info",
          questionX: "Question #{num}",
          answerX: 'Option #{num}'
        }
      },
      question: {
        question: 'Question',
        deleteQuestion: 'Delete Question',
        addOption: 'Add Option',
        deleteOption: 'Delete Option',
        deleteQuiz: 'Delete Quiz',
        maxAnswers: {
          label: 'Max answers',
          help: '0 - for any or none, 1+ - maximum possible number, at least one'
        },
        timer: {
          label: 'Timer',
          help: 'Time lime for unswer in seconds. 0 - unlimited'
        },
        answer: {
          text: 'Answer text',
          points: 'Answer points',
          image: 'Answer Image'
        },
        dialog: {
          deleteQuestion: {
            title: 'Delete?',
            message: 'Delete question {num}?'
          },
          deleteOption: {
            title: 'Delete?',
            message: 'Delete option {num}?'
          },
          deleteQuiz: {
            title: 'Delete?',
            message: 'Delete quiz? All data will be lost!'
          }
        }
      },
      quizListPage:{
        title: "Quizzes",
        createQuizButtonText: "Create Quiz",
        peopleAllowed: "Number of people allowed"
      },
      createQuizPage:{
        title: "Create Quiz",
        scoresTitle: "Quiz rules",
        fields:{
          name: "Quiz Name",
          description: "Quiz description",
          intro: "Quiz intro",
          type: "Quiz type",
          repeats: "Max times to play (by user)",
          successScore: "Success Score",
          successMessage: "Success Message",
          failMessage: "Fail Message",
          maximumPersonAllowed: "Maximum People can play",
          submitButton:"Create Quiz",
          submitEditButton:"Update Quiz"
        },
      }
    },
    activity: {
      type: {
        [ActivityType.Content]: 'Content',
        [ActivityType.LiveBoard]: 'Live board',
        [ActivityType.Mosaic]: 'Mosaic',
        [ActivityType.Presentation]: 'Presentation',
        [ActivityType.Quizz2D]: 'Quizz 2D',
        [ActivityType.Quizz3D]: 'Quizz 3D',
        [ActivityType.Map]: 'Kiosk Map',
        [ActivityType.Screen]: 'Screen',
        [ActivityType.SCORM]: 'SCORM',
        [ActivityType.SessionMap]: 'Session Map',
        [ActivityType.UrlRedirect]: 'Url Redirect'
      }
    },
    error: {
      error: 'Error!',
      saveFailed: 'Save failed'
    },
    activityPage:{
      activityListPage:{
        title: "Activities",
        createButton: "Create Activity"
      },
      dialogs: {
        deleteMedia: {
          title: 'Delete?',
          message: 'Delete media file?'
        },
        deleteActivity: {
          title: 'Delete?',
          message: 'Delete activity {type} - {name}?\nAll related data will be lost!'
        }
      },
      createActivityPage:{
        title: "Add New Activity",
        labels:{
          files: "Content files",
          createButton: "Create Activity",
          makeItDownloadable: "Downloadable",
          createRequired: 'Save activity required to use other configurations'
        },
        fileds:{
          chooseActivity: "Select Activity Type",
          name: "Name",
          video: "Video (embeded html)",
          url: "Url",
          blank: "Open in new window/tab",
          description: "Description",
          contentHeader: 'Content',
          zipFile: 'ZIP file',
          mediaFile: 'Media file',
          mediaDelete: 'remove file',
          typeContent: {
            text: "Text",
            image: "Upload Image",
            pdf: "Upload PDF",
          },
          typeMosaic:{
            image: "Upload Image"
          },
          typeScreen:{
            video: "Upload Video"
          },
          links: {
            d1fr: 'Day 1 France',
            d1uk: 'Day 1 UK',
            d1us: 'Day 1 US',
            d1fruk: 'Day 1 France & UK',
            d2fr: 'Day 2 France',
            d2uk: 'Day 2 UK',
            d2fb: 'Day 2 Facebook Q&A',
            d2east: 'Day 2 CM East',
            d2west: 'Day 2 CM West',
            d2central: 'Day 2 CM Central',
            d2room1: 'Day 2 BI Room 1',
            d2room2: 'Day 2 BI Room 2',
            d2room3: 'Day 2 BI Room 3',
          }
        }
      }
    }
  },
  //Help Page
  helpPage: {
    textPage:{
      title: "Welcome to the Hacker's Academy!",
      content: "Here are some things you should know before the experience begins:<br/><br/>Google Chrome is the recommended web browser to use in this environment. Please make sure your version of Google Chrome is up to date to ensure a quality experience.<br/><br/>Internet requirements: 10mbps upload and download<br/><br/>The two days will be a mix between presentations, guest speakers, and breakout sessions.<br/><br/>You will need a Microsoft Teams account for the breakout sessions.<br/><br/>There will be different surprises in the environments, be sure to explore and click around to discover them all in the free roam time.<br/><br/>You can navigate around the rooms with the following controls: <br/>• Arrow keys: Move around the room<br/>• Click and drag: Move the camera perspective<br/>• Mouse: Move around and camera perspective"
    },
    eventScedule: {
      title: "Event Schedule",
      caption: "All scheduled sessions will be a mix of broadcasted content and breakout rooms. You will either be directed to them  automatically or need to click on the applicable link in the calendar when you are in the Hacker's Academy."
    },
    notification:{
      title: "Your Notifications",
    },
    portalMenu:{
      title: "Portal Menu:",
      caption: "Navigate to Locations Here",
    },
    mute:{
      title: "Mute"
    },
    keyboardControls:{
      title: "Keyboard Controls",
      switchWASD:{
        w: "Look Up",
        a: "Look Left",
        s: "Look Down",
        d: "Look Right",
      },
      switchArrows:{
        up: "Move Forward",
        left: "Move Left",
        down: "Move Back",
        right: "Move Right",
      }
    },
    mouseHelp:{
      content: "You can also click & drag your mouse to pivot your viewpoint"
    },
    clickHelp:{
      title: "Hacker's Academy Touch Points",
      content: "Anything that highlights as you hover over it in the rooms are touch points. Click to interact and find all of the hidden surprises!"
    },
    profile:{
      title: "Your Profile"
    },
    setting:{
      title: "Settings:",
      caption: "User Guide & Log Out"
    },
    leaderboard: {
      title: "Leaderboard"
    },
    chat:{
      title: "Who's Here",
      caption: "Quick access to chat"
    },
    videoChat:{
      title: "Video Chat"
    },
    dontShowButton: "Got it!"
  },
  // Chat page
  chat: {
    groupChat:{
      title: "Group Chat",
    },
    whoIsOnline:{
      title:"Who is online",
      noOneOnline: "Nobody is online",
      search: 'Search Users'
    },
    search: 'search...'
  },
  notification:{
    widget:{
      title: "Notification",
      noNotification:"All Good, You are upto date!"
    },
    newMessage: 'New message from <b>{from}</b>'
  },
  mosaicOverlay:{
    dropzone:{
      placeholder: "Drag and Drop or <b>browse</b> your file",
      or: "or"
    }
  }
}

module.exports = en

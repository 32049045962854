import { createStore } from 'vuex'
import init from '@/store/init'
import ui from '@/store/ui'
import user from '@/store/user'
import pc from '@/store/pc'
import collections from '@/store/collections'
import admin from '@/store/admin'
import scene from '@/store/scene'

const store = createStore({
  modules: {
    init,
    ui,
    user,
    pc,
    collections,
    admin,
    scene
  },
  state: {
    initialized: false,
    initEvent: null,
    touched: false,
    touchHandler: null
  },
  mutations: {
    setTouch: (state, next) => {
      // console.log('setTouch', state.touchHandler, next)
      if (state.touchHandler) {
        state.touchHandler()
        state.touchHandler = null
      }
      state.touched = next
    },
    setTouchHandler: (state, next) => {
      state.touchHandler = next
    }
  },
  actions: {
    init: async (ctx, opts) => {
      // not waiting here, cuz
      ctx.dispatch('ui/initialize')
      ctx.dispatch('user/initialize',opts)
      // wainting here for user module to be fully initialized
      await ctx.dispatch('user/init/next')
      await ctx.dispatch('collections/initialize')
      ctx.commit('init/set', true)
    },
    touch: ctx => new Promise(accept => {
      if (ctx.state.touched) {
        //console.log('already touched')
        accept()
        return 
      }
      ctx.commit('setTouchHandler', accept)
      ctx.commit('pc/setSplash', {
        loader: false,
        video: '',
        skip: false,
        touch: true
      })
    })
  }
})

export default store

import dayjs from 'dayjs'

// countdown date
const dayz = dayjs('2021-09-28 05:45 GMT-7')

export const isDayz = () => {
  const now = dayjs()
  return dayz.isBefore(now)
}

export default dayz

/**
  Firestore collection wrapped to vuex store
  @param name [String|FirestoreCollectionObject]
  @param Model - reference model object
*/
import genID from '@/utils/genID'
import init from '@/store/init'

const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')
const firestoreCollection = (name, Model, idField = 'id') => ({
  namespaced: true,
  modules: { init },
  state: {
    data: {},
    isLoaded: false,
    subscription: null
  },
  mutations: {
    setData: (state, data) => {
      state.data = data
      state.isLoaded = true
    },
    setSubscription: (state, next) => {
      //unsubscribe on previous subscription
      if (state.subscription)
        state.subscription()
      state.subscription = next
    }
  },
  getters: {
    collection: () => async () => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      return typeof name === 'string' ? firebase.firestore().collection(name) : name
    },
    list: state => Object.values(state.data),
    ids: state => Object.keys(state.data),
    byID: state => id => state.data[id],
    docRef: () => async id => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default
      const coll = typeof name === 'string' ? firebase.firestore().collection(name) : name
      return coll.doc(id)
    }
  },
  actions: {
    /** Smart subscribes to collection, possible with query */
    smartSubscribe: async (ctx, query = null) => {
      const collection = await ctx.getters.collection()
      const ref = query ? collection.where(...query) : collection
      const sub = ref.onSnapshot(snap => {
        const data = {}
        snap.forEach(doc => {
          const docData = doc.data()
          const docId = doc.id
          docData[idField] = docId
          data[docId] = docData
        })
        ctx.commit('setData', data)
        ctx.commit('init/set',true)
      })
      ctx.commit('setSubscription', sub)
 
    },
    /** Subscribes to collection, possible with query */
    subscribe: async (ctx, query = null) => {
      const collection = await ctx.getters.collection()
      const ref = query ? collection.where(...query) : collection
      const sub = ref.onSnapshot(snap => {
        const data = {}
        snap.forEach(doc => {
          const docData = doc.data()
          const docId = doc.id
          docData[idField] = docId
          data[docId] = docData
        })
        ctx.commit('setData', data)
        ctx.commit('init/set',true)
      })
      ctx.commit('setSubscription', sub)
    },
    /** Builds collection query with full params */
    buildQuery: async (ctx, { query = null, orderBy = null /*, page = 0, pageSize = 10 */ }) => {
      const collection = await ctx.getters.collection()
      let ref = !query 
        ? collection
        : !Array.isArray(query[0])
          ? collection.where(...query)
          : query.reduce((acc,q) => acc.where(...q), collection)
      if (orderBy) {
        if (Array.isArray(orderBy)) {
          if (Array.isArray(orderBy[0]))
            ref = orderBy.reduce((acc,ord) => Array.isArray(ord) ? acc.orderBy(...ord) : acc.orderBy(ord), ref)
          else
            ref = ref.orderBy(...orderBy)
        } else {
          ref = ref.orderBy(orderBy)
        }
      }
      return ref
    },
    /** Subscribe to collection with full params */
    query: async (ctx, query) => {
      const ref = await ctx.dispatch('buildQuery', query)
      const sub = ref.onSnapshot(snap => {
        const data = {}
        snap.forEach(doc => {
          const docData = doc.data()
          const docId = doc.id
          docData[idField] = docId
          data[docId] = docData
        })
        ctx.commit('setData', data)
      })
      ctx.commit('setSubscription', sub)
    },
    /** Loads collection snapshot with full params */
    snapshot: async (ctx, query) => {
      const ref = await ctx.dispatch('buildQuery', query)
      const snap = await ref.get()
      const data = {}
      snap.forEach(doc => {
        const docData = doc.data()
        const docId = doc.id
        docData[idField] = docId
        data[docId] = docData
      })
      return data
    },
    /** Firebase reference to document by ID */
    ref: async (ctx, id) => {
      const collection = await ctx.getters.collection()
      return collection.doc(id)
    },
    /** Save document at collection */
    save: async (ctx, doc) => {
      const id = doc[idField] || genID()
      const dbDoc = Object.assign({}, Model, doc)
      delete dbDoc[idField]
      const collection = await ctx.getters.collection()
      const ref = collection.doc(id)
      await ref.set(dbDoc)
      dbDoc[idField] = id
      return dbDoc
    },
    /** Update document at collection by id */
    update: async (ctx, { id, doc }) => {
      delete doc[idField]
      const collection = await ctx.getters.collection()
      const ref = collection.doc(id)
      const cdoc = await ref.get()
      if (!cdoc.exists) {
        console.log('updating not existing document', name, id, doc)
        await ref.set(doc)
        // return null
      }
      await ref.update(doc)
      const rdoc = await ref.get()
      const rdata = rdoc.data()
      rdata[idField] = id
    },
    delete: async (ctx, id) => {
      const collection = await ctx.getters.collection()
      const ref = collection.doc(id)
      await ref.delete()
    }
  }
})

export default firestoreCollection

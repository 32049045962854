<script>
import { Locale } from '@/lib/enums.js'

import FlagUS from '@/assets/imgs/icons/flags/usa.svg'
import FlagFR from '@/assets/imgs/icons/flags/france.svg'
import FlagES from '@/assets/imgs/icons/flags/spain.svg'

export default {
  name: 'LocaleSelector',
  components: {
    FlagUS,
    FlagFR,
    FlagES
  },
  data: () => ({
    open: false
  }),
  setup() {
    return {  Locale }
  },
  methods: {
    clickOut() {
      this.open = false
    },
    selectLocale(next) {
      this.$store.commit('ui/setBusy', true)
      this.open = false
      this.$i18n.locale = next
      this.$store.commit('ui/setLocale', next)
      this.$store.commit('ui/setBusy', false)
    }
  }
}
</script>

<template>
<div v-click-outside="clickOut">
  <v-btn :disabled="$store.state.ui.busy" icon flat @click="open = true">
    <FlagUS v-if="$store.state.ui.locale === Locale.en"/>
    <FlagFR v-if="$store.state.ui.locale === Locale.fr"/>
    <FlagES v-if="$store.state.ui.locale === Locale.es"/>
  </v-btn>
  <div v-if="open" class="d-flex flex-column elevation-2 bg-white rounded-lg">
    <v-btn v-if="$store.state.ui.locale !== Locale.en" icon flat elevation="0" @click="selectLocale('en')">
      <FlagUS/>
    </v-btn>
    <v-btn v-if="$store.state.ui.locale !== Locale.fr" icon flat elevation="0" @click="selectLocale('fr')">
      <FlagFR/>
    </v-btn>
    <v-btn v-if="$store.state.ui.locale !== Locale.es" icon flat elevation="0" @click="selectLocale('es')">
      <FlagES/>
    </v-btn>
  </div>
</div>
</template>

<script>
import LocaleSelector from '@/components/LocaleSelector'
import HeakersAcedemyLogo from "@/assets/imgs/admin-logo-white.svg"

export default {
  name: 'Page2DLayout',
  components: {
    LocaleSelector,
    HeakersAcedemyLogo
  },
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  
}
</script>

<template>
  <v-layout class="fill-height">
    <v-row no-gutters dense class="fill-height">
      <v-col cols="6" class="bg-fb-navy" id="image-column">
        <HeakersAcedemyLogo class="login-flow-logo"/>
      </v-col>
      <v-col>
        <v-container class="fill-height" fluid>
          <v-row align="center" justify="center" class="fill-height overflow-y-auto custom-scrollbar">
            <v-col cols="8">
              <LocaleSelector id="locale-selector"/>
              <v-card elevation="0" class="overflow-visible">
                <hr width="70%"/>
                <h1 id="title-2d" class="mb-5 text-grey-2d">{{title}}</h1>
                <slot/>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-layout>
</template>

<style scoped>
#locale-selector {
  position: absolute;
  top: 62px;
  right: 73px;
}
#image-column {
  position: relative;
  /*background-image: url('../assets/imgs/background2d.png');*/
  background-size: cover;
  background-position-x: right;
}

#research-logo {
  position: absolute;
  top: 62px;
  left: 73px;
  fill: #fff;
}

#labs-logo {
  position: absolute;
  width: 109px;
  height: 109px;
  right: 62px;
  bottom: 30px;
}

.login-flow-logo{
  position:absolute;
  width:300px;
  margin: auto;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}

#title-2d {
  font-size: 57px;
}

hr {
  border: 1px #DADADA solid
}
</style>

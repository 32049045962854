import { createApp } from "vue";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import VueGtag from "vue-gtag-next";
import router from "./router";
import store from "./store";
import i18n from "@/i18n";
import directives from "@/directives";
import globalMixins from "@/mixins/global";

import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";

import CKEditor from "@ckeditor/ckeditor5-vue";

import * as pc from "playcanvas";
window.pc = pc;

import can from "@/utils/can";
import env from "@/utils/env";
import checkWebGL from "@/utils/checkWebGL";

const hasWebGL = !checkWebGL();
const isSupported =
  hasWebGL &&
  env.platform.type === "desktop" &&
  ["Chrome", "Chromium", "Firefox" /*, "Safari"*/].includes(env.browser.name);

import { isDayz } from "@/dayz";

router.beforeEach(async (to, from, next) => {
  // console.log('!!next route?', to, from)
  if (!isSupported) {
    if (to.name === "NotSupportedPage") {
      next();
      return;
    }
    let reason = "browser";
    if (!hasWebGL) {
      reason = "webgl";
    }
    if (hasWebGL && env.platform.type === "mobile") {
      reason = "mobile";
    }
    if (hasWebGL && env.platform.type === "tablet") {
      reason = "tablet";
    }
    next(`/not-supported/${reason}`);
    return;
  }
  if (!store.state.init.done) await store.dispatch("init/next");
  if (to.adminStore && !store.state.admin.init.done)
    await store.dispatch("admin/init/next");
  if (to.meta && to.meta.touchRequired) await store.dispatch("touch");
  if (to.meta.noSplash) store.commit("pc/setSplash", null);
  //countdown for everyone except admin

  if (to.meta && to.meta.access) {
    if (can(store.state, to.meta.access)) {
      // console.log('next route?', to, from, can(store.state, 'admin'))
      if (to.name === "CountdownPage") {
        next();
        return;
      }
      if (
        !isDayz() &&
        !(can(store.state, "admin") || can(store.state, "countdown")) &&
        to.name !== "CountdownPage"
      ) {
        next("/countdown");
        return;
      }
      next();
    } else {
      store.commit("pc/setSplash", null);
      next("/404");
    }
    return;
  }
  next();
});

const app = createApp(App)
  .use(i18n)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(CKEditor)
  .mixin(globalMixins);

Object.entries(directives).forEach(([name, directive]) =>
  app.directive(name, directive)
);

app.provide("pc", pc);

// Initiate VueGtag - See https://matteo-gabriele.gitbook.io/vue-gtag/v/next/ for docs
app.use(VueGtag, {
  property: {
    id: process.env.VUE_APP_GTAG_ID,
    useDebugger: process.env.NODE_ENV === 'development', // Enable debugger for testing.
  },
});

// Initialise Sentry
Sentry.init({
  app,
  dsn: process.env.NODE_ENV === 'development' ? "https://6bb121713d66480989bade1089ddec10@o554620.ingest.sentry.io/5982864" : "https://bc8f2116707448b2aed07072920650b5@o554620.ingest.sentry.io/5982854",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins:  process.env.NODE_ENV === 'development' ? ["localhost", "dev.hackersacademyfb.com", /^\//] : ["hackersacademyfb.com", "www.hackersacademyfb.com", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0, 
  logErrors: true // Call vue's original logError function as well
});

app.mount("#app");

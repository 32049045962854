import en from '@/i18n/en'
import es from '@/i18n/es.json'
import fr from '@/i18n/fr.json'

// console.log('en', en)
// console.log('es', es)
// console.log('fr', fr)

import { createI18n } from 'vue-i18n'

import env from '@/utils/env'

const i18n = createI18n({
  locale: localStorage.getItem('ui/locale') || env.locale,
  fallbackLocale: 'en',
  messages: {
    en,
    fr,
    es
  }
})

export default i18n

import { createVuetify } from 'vuetify'
import * as components from 'vuetify/lib/components'
import * as directives from 'vuetify/lib/directives'

import '@/assets/styles.scss'

export default createVuetify({
  components,
  directives,
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          'primary': '#1F8CF7',
          'black': '#000',
          'white': '#ffffff',
          'grey-200': '#CBCBCB',
          'grey-2d': '#374853', 
          'link-2d': '#6B7B8A',
          'error': '#EF466F',
          'admin-grey': '#F7F7F7',
          'admin-grey-2': '#F1F2F4',
          'admin-grey-3': '#F9FAFB',
          'admin-grey-4': '#98A3AB',
          'admin-grey-5': '#6B7280',
          'admin-grey-6': '#acd4e0',
          'admin-text': '#222222',
          'fb-navy-3': '#184e63',
          'fb-navy-2': '#3362AA',
          'fb-navy': '#1C365D',
          'fb-grey': '#64747F',
          'admin-green-400': '#34D399',
          'admin-danger': '#FF5E4B'
        }
      }
    }
  }
})

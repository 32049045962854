/**
  Checks WebGL by starting and destroying empty Playcanvas application

  @return null on success support or error
*/
import * as Sentry from "@sentry/vue";
const pc = require('playcanvas')

const checkWebGL = () => {
  let error = null
  const canvas = document.createElement('canvas')
  var devices = {
    elementInput: new pc.ElementInput(canvas, {
      useMouse: null,
      useTouch: null
    }),
    keyboard: null,
    mouse: null,
    gamepads: null,
    touch: null,
  }
  try {
    const app = new pc.Application(canvas, {
      elementInput: devices.elementInput,
      keyboard: devices.keyboard,
      mouse: devices.mouse,
      gamepads: devices.gamepads,
      touch: devices.touch,
      graphicsDeviceOptions: null,
      assetPrefix: '',
      scriptPrefix: '',
      scriptsOrder: []
    })
    app.destroy()
  } catch (e) {
    Sentry.captureException(e)
    if (e instanceof pc.UnsupportedBrowserError) {
      error = 'Unsupported browser'
    } else if (e instanceof pc.ContextCreationError) {
      error = 'WebGL not supported'
    } else {
      error = e
    }
  }
  return error
}

export default checkWebGL

/**
  file2buffer reads file from input type=file
*/

const file2buffer = file => new Promise((accept, reject) => {
  const reader = new FileReader()
  reader.onerror = reject
  reader.onload = () => {
    accept(reader.result)
  }
  reader.readAsArrayBuffer(file)
})

export default file2buffer

import firestoreCollection from '@/store/firestore'
import init from '@/store/init'

import * as Sentry from "@sentry/vue";
import genID from '@/utils/genID'

// const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')

export default {
  namespaced: true,
  modules: {
    init,
    core: firestoreCollection('dm', { participants: ['',''], timestamp: null})
  },
  state: {
    subs: {},
    messages: {},
    hasNew: {},
    notifications: []
  },
  mutations: {
    setSub: (state, { id, sub }) => {
      if (state.subs[id])
        state.subs[id]()
      state.subs[id] = sub
    },
    updateDM: (state, {dmid, messages}) => {
      state.messages[dmid] = messages
    },
    clearDMs: state => {
      state.messages = {}
    },
    setHasNew:(state, {id, next}) => {
      //console.log('new at', id, next)
      state.hasNew[id] = next
    },
    pushMsgNotification: (state, from) => {
      state.notifications.push(from)
      setTimeout(() => {
        state.notifications.shift()
      }, 4000)
    }
  },
  getters: {
    me: (state, getters, rootState) => rootState.user.data.uid,
    dmID: (state, getters) => to => {
      const me = getters.me
      const [userA, userB] = me < to ? [me, to] : [to, me]
      const id = `${userA}_${userB}`
      return id
    },
    messages: (state, getters) => withID => {
      return state.messages[getters.dmID(withID)] || []
    },
    list: (state, getters, rootState, rootGetters) => {
      const me = getters.me
      const users = getters['core/list']
        .map(dm => dm.participants[0] === me ? dm.participants[1] : dm.participants[0])
        .map(uid => rootGetters['collections/users/byID'](uid))
      return users
    }
    // list: state => Object.values(
  },
  actions: {
    subscribe: async ctx => {
      const me = ctx.getters.me
      // console.log('init dm', me)
      await ctx.dispatch('core/query',{
        query: ['participants', 'array-contains', me],
        orderBy: ['timestamp', 'desc']
      })
      //await ctx.dispatch('reload')
      ctx.commit('init/set', true)
    },
    unsubscribe: async ctx => {
      Object.keys(ctx.state.subs).forEach(sub => {
        ctx.commit('setSub', { id: sub, sub: null })
      })
      ctx.commit('clearDMs')
      ctx.commit('init/set', false)
    },
    reload: async ctx => {
      const dms = ctx.getters['core/list']
      //console.log('reload', dms)
      //let reload = true
      dms.forEach(async dm => {
        if (ctx.state.subs[dm.id])
          return
        const core = await ctx.getters['core/collection']()
        const dmDocRef = core.doc(dm.id)
        const msgs = dmDocRef.collection('message')
        const sub = msgs.orderBy('timestamp','desc').onSnapshot(snap => {
          const docs = {}
          snap.forEach(rec => {
            const doc = rec.data()
            doc.id = rec.id
            docs[doc.id] = doc
          })
          //console.log('dm updated!', dm.id, ctx.state.hasNew[dm.id], docs)
          ctx.commit('updateDM', { dmid: dm.id, messages: docs })
          const nextNew = typeof ctx.state.hasNew[dm.id] !== 'undefined'
          //if (typeof ctx.state.hasNew[dm.id] !== 'undefined') 
          ctx.commit('setHasNew', { id: dm.id, next: nextNew})
        })
        ctx.commit('setSub', { id: dm.id, sub })
      })
      //reload = false
    },
    send: async (ctx, { to, msg }) => {
      try {
        const me = ctx.getters.me
        //console.log('sending dm', me, to, msg)
        const [userA, userB] = me < to ? [me, to] : [to, me]

        const id = `${userA}_${userB}`
        //console.log(' dm id ', id)
        const core = await ctx.getters['core/collection']()
        const dmDocRef = core.doc(id)
        const dmDoc = await dmDocRef.get()
        if (!dmDoc.exists) {
          await dmDocRef.set({
            participants: [userA, userB],
            timestamp: new Date()
          })
          //console.log('dmdoc created!')
        }
        const msgs = dmDocRef.collection('message')
        const msgID = genID()
        const msgRef = msgs.doc(msgID)
        await msgRef.set({
          content: msg,
          role: ctx.rootState.user.data.role,
          timestamp: new Date(),
          user: ctx.rootState.user.data.uid
        })
        //console.log('dm message created!')
        await dmDocRef.update({
          timestamp: new Date()
        })
        //console.log('dmdoc updated!')
        if (!ctx.state.subs[id]) {
          const sub = msgs.orderBy('timestamp','desc').onSnapshot(snap => {
            const docs = {}
            snap.forEach(rec => {
              const doc = rec.data()
              doc.id = rec.id
              docs[doc.id] = doc
            })
            //console.log('dm updated!', id, docs)
            ctx.commit('updateDM', { dmid: id, messages: docs })
          })
          ctx.commit('setSub', { id, sub })
          //console.log('dm subscribed!')
        }

        //console.log('dm send' , to, msg, id, dmDoc)
      } catch (err) {
        Sentry.captureException(err)
        console.log('catched!')
        console.error(err)
        return false
      }
      return true
    },
    notifyNewMessage: ctx => {
      const newDMs = Object.keys(ctx.state.hasNew).filter(dmid => !!ctx.state.hasNew[dmid])
      newDMs.forEach(dmid => {
        const uids = dmid.split('_')
        const fromid = uids[0] === ctx.rootState.user.data.uid ? uids[1] : uids[0]
        const from = ctx.rootGetters['collections/users/byID'](fromid)
        ctx.commit('pushMsgNotification', from)
        //console.log('notify about new mesage from', from, fromid)
      })
    }
  },
}

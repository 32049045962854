import firestoreCollection from '@/store/firestore'
import genID from '@/utils/genID'
import { collection as Collections } from '@/lib/collections'
import { UserRole, ActivityType, Semantic } from '@/lib/enums'

import init from '@/store/init'
import can from '@/utils/can'

// console.log('collections', Collections, Collections.activity)
const GameModel = {
  content: { },
  type: 0
}

export default {
  namespaced: true,
  modules: {
    init,
    prescreened: firestoreCollection('prescreened', {approved: false, role: UserRole.Guest }, 'email'),
    users: firestoreCollection('user', Collections.user(), 'uid'),
    scenes: firestoreCollection('scene', { id: 0 }, 'id'),
    activities: firestoreCollection('activity', GameModel, 'id'),
    scorm: firestoreCollection('scorm', GameModel, 'id')
  },
  state: {
    initialized: false,
    uiAlert: {
      handler: null,
      title: '',
      message: '',
      icon: null,
      color: ''
    },
    uiConfirm: {
      handler: null,
      title: '',
      message: '',
      icon: null,
      color: ''
    },
  },
  getters: {
    quizes: state => {
      return Object.values(state.activities.data || {})
        .filter(activity => activity.type === ActivityType.Quizz2D || activity.type === ActivityType.Quizz3D)
    },
    presentations: state => {
      return Object.values(state.activities.data || {})
        .filter(activity => activity.type === ActivityType.Presentation)
    },
    otherActivities: state => {
      return Object.values(state.activities.data || {})
        .filter(activity => activity.type !== ActivityType.Quizz2D && activity.type !== ActivityType.Quizz3D && activity.type !== ActivityType.Presentation)
    },
    registered: state => Object.values(state.users.data || {})
        .filter(user => user.role !== UserRole.Guest),
    administrators: state => Object.values(state.users.data || {})
        .filter(user => user.role === UserRole.CommunityManager || user.role === UserRole.Root || user.role === UserRole.Trainer),
    guests: state => Object.values(state.users.data || {})
        .filter(user => user.role === UserRole.Guest && !user.reviewDate),
    rejected: state => Object.values(state.users.data || {})
        .filter(user => user.role === UserRole.Guest && user.reviewDate),
  },
  mutations: {
    setInitialized: (state, next) => { state.initialized = next },
    alert: (state, data) => {
      state.uiAlert = data
    },
    confirm: (state, data) => {
      state.uiConfirm = data
    },
    closeAlert: (state) => {
      state.uiAlert.handler()
      state.uiAlert.handler = null
    },
    closeConfirm: (state, val) => {
      state.uiConfirm.handler(val)
      state.uiConfirm.handler = null
    }
  },
  actions: {
    subscribe: async ctx => {
      if (ctx.state.inintialized) return
      await ctx.dispatch('users/subscribe')
      if (can(ctx.rootState, 'users_management')) {
        // console.log('can users_management')
        await ctx.dispatch('prescreened/subscribe')
      }
      await ctx.dispatch('activities/subscribe')
      await ctx.dispatch('scenes/subscribe')
      await ctx.dispatch('scorm/subscribe')
      ctx.commit('setInitialized', true)
      ctx.commit('init/set', true)
    },
    /** Unsibscribe collections. Mainly on user logout */
    unsubscribe: async ctx => {
      if (!ctx.state.initialized) return
      ctx.commit('users/setSubscription', null)
      ctx.commit('prescreened/setSubscription', null)
      ctx.commit('activities/setSubscription', null)
      ctx.commit('scenes/setSubscription', null)
      ctx.commit('scorm/setSubscription', null)
      ctx.commit('setInitialized', false)
      ctx.commit('init/set', false)
    },
    /** Display alert message with 'OK' button */
    alert: (ctx, {title = 'Alert', message = '', icon = null, color = ''}) => new Promise(handler => {
      ctx.commit('alert',{ title, message, icon, color, handler })
    }),
    /**
        Display confirm message with 'No/Yes' button
        @returns Promise<Boolean>
     */
    confirm: (ctx, {title = 'Confirm', message = '', icon = null, color = ''}) => new Promise(handler => {
      ctx.commit('confirm',{ title, message, icon, color, handler })
    }),
    /**
      Change user's role
    */
    setUserRole: async (ctx, { uid, role}) => {
      await ctx.dispatch('users/update', { id: uid, doc: {
        role,
        approved: role !== UserRole.Guest,
        reviewDate: new Date()
      }})
    },
    /**
      Ban/reject user
    */
    banUser: async (ctx, uid) => {
      await ctx.dispatch('users/update', { id: uid, doc: {
        role: UserRole.Guest,
        approved: false,
        reviewDate: new Date()
      }})
    },
    /**
      Push Voice of God notification
    */
    pushMediaNotification: async (ctx, data) => {
      const coll = await ctx.getters['users/collection']()
      data.to.forEach(async uid => {
        const nid = genID()
        const notification = {
          content: [data.content],
          createdAt: new Date(),
          immediate: data.immidiate,
          readAt: { seconds: 0 },
          type: Semantic.Info,
          //url: none
        }
        const nref = coll.doc(uid).collection('notification').doc(nid)
        await nref.set(notification)
      })
    },
    pushTextNotification: async (ctx, data) => {
      const coll = await ctx.getters['users/collection']()
      data.to.forEach(async uid => {
        const nid = genID()
        const notification = {
          content: [{text: data.content}],
          createdAt: new Date(),
          immediate: false,
          readAt: { seconds: 0 },
          type: Semantic.Info,
          // url: none
        }
        const nref = coll.doc(uid).collection('notification').doc(nid)
        await nref.set(notification)
      })
    }
  }
}

import firestoreCollection from '@/store/firestore'
import { collection as Collections } from '@/lib/collections'

const firebaseImport = () => import(/* webpackChunkName: "firebase" */ '@/firebase')

export default {
  namespaced: true,
  modules: {
    activities: firestoreCollection('activity', Collections.activity()),
    users: firestoreCollection('user', Collections.user(), 'uid')
  },
  actions: {
    initialize: async ctx => {
      await ctx.dispatch('activities/subscribe')
      await ctx.dispatch('users/subscribe')
    },
    getActivityLeaderboard: async (ctx, source) => {
      const firebaseModule = await firebaseImport()
      const firebase = firebaseModule.default

      const aid = typeof source === 'object'
        ? source.id
        : source
      const req = source === 'global' || !source
        ? firebase.firestore().collectionGroup('score')
          .orderBy('score', 'desc')
          .orderBy('runtime')
        : firebase.firestore().collectionGroup('score')
          .where('activity', '==', aid)
          .orderBy('score', 'desc')
          .orderBy('runtime')
      const snap = await req.get()
      const docs = {}
      snap.forEach(rec => {
        const doc = rec.data()
        const path = rec.ref.path
        if (!path.startsWith('user/'))
          return
        const uid = rec.ref.parent.parent.id
        doc.id = rec.id
        docs[doc.id] = doc
        docs[doc.id].uid = uid
      })
      return docs
    }
  }
}

import init from "@/store/init";
import { Scene, UserRole, ActivityType } from "@/lib/enums";
// import waitFor from '@/utils/waitFor'
import genID from "@/utils/genID";

import capitalize from "@/utils/capitalize";
import * as Sentry from "@sentry/vue";

import { useGtag } from "vue-gtag-next";

const firebaseImport = () =>
  import(/* webpackChunkName: "firebase" */ "@/firebase");

export default {
  namespaced: true,
  modules: { init },
  state: {
    name: "",
    data: {},
    touchpoints: {},
    subTouchpoints: null,
    eastereggs: {},
    subEastereggs: null,
    messages: {},
    subMessages: null,
    lastTouchPoint: null,
    redirectOnActionClose: null,
  },
  getters: {
    aref: () => async (aid) => {
      const firebaseModule = await firebaseImport();
      const firebase = firebaseModule.default;
      //const name = `activity/${aid}/${sub}`
      return firebase
        .firestore()
        .collection("activity")
        .doc(aid);
    },
    collection: (state) => async (sub) => {
      const firebaseModule = await firebaseImport();
      const firebase = firebaseModule.default;
      const name = `scene/${state.name}/${sub}`;
      return firebase.firestore().collection(name);
    },
    ref: (state) => async () => {
      const firebaseModule = await firebaseImport();
      const firebase = firebaseModule.default;
      return firebase
        .firestore()
        .collection("scene")
        .doc(state.name);
    },
    messages: (state) => Object.values(state.messages),
  },
  mutations: {
    setSubTouchpoints: (state, next) => {
      if (state.subTouchpoints) state.subTouchpoints();
      state.subTouchpoints = next;
    },
    setSubEastereggs: (state, next) => {
      if (state.subEastereggs) state.subEastereggs();
      state.subEastereggs = next;
    },
    setData: (state, next) => {
      state.data = next;
    },
    setName: (state, next) => {
      state.name = next;
    },
    setLastTouchPoint: (state, next) => {
      state.lastTouchPoint = next;
    },
    setTouchpoints: (state, next) => {
      state.touchpoints = next;
    },
    setEastereggs: (state, next) => {
      state.eastereggs = next;
    },
    setMessages: (state, next) => {
      state.messages = next;
    },
    setSubMessages: (state, next) => {
      if (state.subMessages) state.subMessages();
      state.subMessages = next;
    },
    setRedirectOnActionClose: (state, next) => {
      state.redirectOnActionClose = next;
    },
  },
  actions: {
    loadMosaics: async (ctx, activities) => {
      for(const act of activities) {
        const aref = await ctx.getters.aref(act.id)
        const mcoll = aref.collection('media')
        const snap = await mcoll.get()
        const me = ctx.rootState.user.data.uid
        const selfies = []
        snap.forEach(rec => {
          const img = rec.data()
          if (img.createdBy === me) {
            selfies.push(img)
          }
        })
        if (!selfies.length || !ctx.rootState.pc.app)
          return
        const selfie = selfies.sort((a,b) => {
          const d1 = a.createdAt.seconds
          const d2 = b.createdAt.seconds
          return d1 === d2 
            ? 0
            : d1 > d2
              ? -1
              : 1
        })[0]
        ctx.rootState.pc.app.fire('3d:setTexture', selfie.details.url)
      }
    },
    enter: async (ctx, scene) => {
      // console.log("enter scene", scene);
      ctx.commit("setName", scene);

      // ANALYTICS - Record scene enter
      const { event } = useGtag();
      const track = () => {
        event("scene_enter", {
          event_category: "scene",
          event_label: "enter",
          scene_name: scene,
          userUid: ctx.rootState.user.data.uid,
          userRole: UserRole[ctx.rootState.user.data.role],
          userEmail: ctx.rootState.user.data.email,
          userDisplayName: ctx.rootState.user.data.displayName,
        });
      };
      track();
      // --

      await ctx.dispatch(
        "user/updateProfile",
        {
          profile: {
            envLocation: { scene: Scene[capitalize(scene)] },
          },
        },
        { root: true }
      );

      const ref = await ctx.getters.ref();
      const refSnap = await ref.get();
      ctx.commit("setData", refSnap.data());

      const collTouchpoints = await ctx.getters.collection("touchpoints");
      const subTouchpoints = collTouchpoints.onSnapshot((snap) => {
        const docs = {};
        const aids = []
        snap.forEach((doc) => {
          const data = doc.data();
          data.id = parseInt(doc.id);
          docs[data.id] = data.activity;
          if (data.activity.length)
            aids.push(data.activity)
        });
        ctx.commit("setTouchpoints", docs);
        //need load Mosaics
        //console.log('ats', ActivityType)
        const activities = aids
          .map(aid => ctx.rootState.collections.activities.data[aid])
          .filter(act => act.type === ActivityType.Mosaic)
        //console.log('enter scene', scene, aids, activities)
        ctx.dispatch('loadMosaics', activities)
      });
      ctx.commit("setSubTouchpoints", subTouchpoints);


      const uid = ctx.rootState.user.data.uid
      const collEastereggs = await ctx.getters.collection("eastereggs");
      const subEastereggs = collEastereggs.onSnapshot(snap => {
        const docs = {};
        let ok = false
        snap.forEach(async (doc) => {
          const data = doc.data();
          data.id = parseInt(doc.id);
          const aid = data.activity
          if (aid && aid.length) {
            const aref = await ctx.getters.aref(aid)
            const uref = aref.collection('participant').doc(uid)
            const udoc = await uref.get()
            //const adoc = await aref.get()
            //console.log('eg docs', data, doc.id, aref, udoc, udoc.exists)
            if (udoc.exists) {
              docs[data.id] = ''
            } else {
              docs[data.id] = data.activity
              ok = true
            }
          } else {
            docs[data.id] = ''
          }
          //console.log('ap', ctx.rootState.pc.app, data.id, ok)
          if (ctx.rootState.pc.app)
            ctx.rootState.pc.app.fire('3d:setEggStage', data.id, ok)
        });
        ctx.commit("setEastereggs", docs);
      });
      ctx.commit("setSubEastereggs", subEastereggs);

      const collMessages = await ctx.getters.collection("messages");
      const subMessages = collMessages
        .orderBy("timestamp", "desc")
        .onSnapshot((snap) => {
          const docs = {};
          snap.forEach((doc) => {
            const data = doc.data();
            data.id = doc.id;
            docs[data.id] = data;
          });
          // console.log('scene messages', docs)
          ctx.commit("setMessages", docs);
        });
      ctx.commit("setSubMessages", subMessages);
    },
    leave: async (ctx, scene) => {
      // console.log("leave");

      // ANALYTICS - Record activity enter
      const { event } = useGtag();
      const track = () => {
        event("scene_leave", {
          event_category: "scene",
          event_label: "leave",
          scene_name: scene,
          userUid: ctx.rootState.user.data.uid,
          userRole: UserRole[ctx.rootState.user.data.role],
          userEmail: ctx.rootState.user.data.email,
          userDisplayName: ctx.rootState.user.data.displayName,
        });
      };
      track();
      // --

      ctx.commit("setSubTouchpoints", null);
      ctx.commit("setSubMessages", null);
    },
    activityEnter: async (ctx, data) => {
      if (!data || !data.id)
        return
      const uid = ctx.rootState.user.data.uid;
      //console.log("activityEnter", data, uid);
      const aref = await ctx.getters.aref(data.id);
      const pcoll = aref.collection("participant");
      const id = uid;
      const now = new Date();
      const pref = pcoll.doc(id);
      await pref.set({
        start: now,
      });

      //console.log("activityEnter 2", data, uid);

      // ANALYTICS - Record activity enter
      const { event } = useGtag();
      const track = () => {
        event("activity_enter", {
          event_category: "activity",
          activity_id: data.id,
          activity_name: data.content.name[0],
          activity_description: data.content.description[0],
          userUid: uid,
          userRole: UserRole[ctx.rootState.user.data.role],
          userEmail: ctx.rootState.user.data.email,
          userDisplayName: ctx.rootState.user.data.displayName,
        });
      };
      track();
      // --

      return { pid: id, start: now, aid: data.id };
    },
    activityLeave: async (ctx, pinfo) => {
      //console.log('activity leave!', pinfo, data)
      const aref = await ctx.getters.aref(pinfo.aid);
      const pcoll = aref.collection("participant");
      //const id = uid
      const now = new Date();
      const diff = now.getTime() - pinfo.start.getTime();
      const pref = pcoll.doc(pinfo.pid);
      const upd = {
        end: now,
        runtime: diff,
      };
      if (typeof pinfo.score !== "undefined") upd.score = pinfo.score;

      // console.log("Activity Leave", { data, pinfo });

      // ANALYTICS - Record activity leave
      const { event } = useGtag();
      const track = () => {
        event("activity_leave", {
          event_category: "activity",
          activity_end: upd.end,
          activity_time_diff: upd.runtime,
          activity_score: pinfo.score,
          userUid: ctx.rootState.user.data.uid,
          userRole: UserRole[ctx.rootState.user.data.role],
          userEmail: ctx.rootState.user.data.email,
          userDisplayName: ctx.rootState.user.data.displayName,
        });
      };
      track();
      // --

      await pref.update(upd);
    },
    sendMessage: async (ctx, msg) => {
      console.log("send message to", ctx.state.name, msg);
      try {
        const coll = await ctx.getters.collection("messages");
        const id = genID();
        const ref = coll.doc(id);
        await ref.set({
          content: msg,
          role: ctx.rootState.user.data.role,
          timestamp: new Date(),
          user: ctx.rootState.user.data.uid,
        });

        // ANALYTICS - Record sendMessage
        const { event } = useGtag();
        const track = () => {
          event("send_message", {
            event_category: "message",
            messageLength: msg.length,
            userUid: ctx.rootState.user.data.uid,
            userRole: ctx.rootState.user.data.role,
            userEmail: ctx.rootState.user.data.email,
            userDisplayName: ctx.rootState.user.data.displayName,
          });
        };
        track();
        // --
      } catch (err) {
        Sentry.captureException(err)
        console.log("sendMessage error caught");
        console.error(err);
        return false;
      }
      return true;
    },
  },
};

<script>

export default {
  name: 'HomePage',
  created() {
    if (!this.$store.state.user.data)
      this.$router.push({ name: 'LoginPage' })
    else
      this.$router.push({ name: '3DPage', params: {scene: 'campus'} })
  }
}
</script>

<template>
  <div/>
</template>
